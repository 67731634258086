import React, {Component} from 'react';
import {auth} from '../../firebase';

const PasswordForgetPage = () => <PasswordForgetForm/>
const byPropKey = (propertyName, value) => () => ({[propertyName]: value});
const INITIAL_STATE = {
  email: '',
  error: null
};
class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      confirmationMessage: '',
      style: {}
    };
  }
  componentDidMount() {
    this.handleScreenDimensions();
    window.addEventListener('resize', this.handleScreenDimensions.bind(this))
  }

  handleScreenDimensions() {
    this.setState({
      style: {
        minHeight: window.innerHeight
      }
    })
  }
  onSubmit = (event) => {
    event.preventDefault();
    const {email} = this.state;
    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          ...INITIAL_STATE,
          confirmationMessage: 'Password change request sent.  Please check your email to reset your account pas' +
              'sword'
        });

      })
      .catch(error => {
        this.setState(byPropKey('error', error));
        this.setState({error, confirmationMessage: ''})
      });
  }
  render() {
    const {email, error, confirmationMessage} = this.state,
      isInvalid = email === '';
    return (
      <main>
        <div className="fullPageFormFrame grey" style={this.state.style}>
          <div className="flex items-center w-full">
            <div className="form bg-white rounded-lg border shadow-lg max-w-sm mx-auto p-5">
              <h1 className="text-2xl text-secondary mb-4">Reset your password</h1>
              <form onSubmit={this.onSubmit}>
                <div className="inputWrapper">
                  <input
                    value={email}
                    onChange={event => this.setState(byPropKey('email', event.target.value))}
                    type="text"
                    placeholder="Email Address"
                    className="formControl"/>
                </div>
                <button
                  disabled={isInvalid}
                  type="submit"
                  className="bg-primary text-white rounded-lg w-full py-2 px-6">
                  Reset My Password
                </button>
                <div className="mt-4">
                  <p className="text-green-500 font-bold">{confirmationMessage}</p>
                  {error && <p className="text-red-500 font-bold">{error.message}</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default PasswordForgetPage;

export {PasswordForgetForm};